














































import { Vue, Component, PropSync, Watch, Ref } from 'vue-property-decorator'

@Component
export default class DialogoDeEansDeProduto extends Vue {
	@Ref() campos!: HTMLInputElement[]
	@PropSync('value')
	eans!: string[]
	mostra = false

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		this
	}

	inserirEan() {
		this.eans.push('')
		setTimeout(() => this.campos[this.campos.length -1].focus())
	}

	atualizarEan(indice: number, valor: string) {
		const eans = [...this.eans]
		eans.splice(indice, 1, valor)
		this.$emit('input', eans)
	}
}

