

















































































































































































































































































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import { SaveProdutoUseCase, FindProdutoUseCase } from '@/usecases'
import type { TipoDeProduto } from '@/models'
import {
	Produto,	
	FormProduto,
	RegraFiscalDeProduto,
	ImagensProduto,
} from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import SeletorDeMarca from '@/components/produto/SeletorDeMarca.vue'
import SeletorDeGenero from '@/components/produto/SeletorDeGenero.vue'
import SeletorDeNcm from '@/components/ui/SeletorDeNcm.vue'
import SeletorDeCest from '@/components/produto/SeletorDeCest.vue'
import SeletorDeUnidadeDeMedida from '@/components/produto/SeletorDeUnidadeDeMedida.vue'
import DialogoDeEansDeProduto from '@/components/produto/DialogoDeEansDeProduto.vue'
import CamposDeProdutoPadrao from './CamposDeProdutoPadrao.vue'
import CamposDeProdutoComVariantes from './CamposDeProdutoComVariantes.vue'
import CamposDeProdutoComposto from './CamposDeProdutoComposto.vue'
import CamposAvancadosDoProduto from './CamposAvancadosDoProduto.vue'
import { ProdutoModule } from '@/store/vuex/produto/ProdutoStore'
import { obrigatorio, maiorOuIgualAZero } from '@/shareds/regras-de-form'
import SeletorDeCategoria from '@/views/application/produtos/EdicaoDeProduto/SeletorDeCategoria.vue'
import {
	converterProdutoParaProdutoPadrao,
	converterProdutoParaProdutoComVariantes,
	converterProdutoParaProdutoComposto,
	criarProduto,
} from '@/shareds/produto-shareds'
import { nextTick } from '@/shareds/utils'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import SeletorDeEstacao from '@/components/produto/SeletorDeEstacao.vue'

@Component({
	components: {
		EdicaoGenerica,
		SeletorDeTag,
		SeletorDeMarca,
		SeletorDeGenero,
		CamposDeProdutoPadrao,
		CamposDeProdutoComVariantes,
		CamposDeProdutoComposto,
		CamposAvancadosDoProduto,
		SeletorDeNcm,
		SeletorDeCest,
		DialogoDeEansDeProduto,
		SeletorDeUnidadeDeMedida,
		SeletorDeCategoria,
		SeletorDeEstacao,
	},
})
export default class EdicaoDeProduto extends Vue {
	@Ref() edicaoGenerica!: EdicaoGenerica<Produto>
	@Prop({ type: String }) id!: string | undefined
	@Prop({ type: Boolean }) novo!: boolean
	campoDeDesconto!: HTMLInputElement

	obrigatorio = obrigatorio
	maiorOuIgualAZero = maiorOuIgualAZero

	podeAlterarCampoDeDesconto = false
	produto: FormProduto | null = null
	versaoAnterior: FormProduto | null = null
	versaoInicial: FormProduto | null = null
	carregando = true
	salvando = false
	img: ImagensProduto | null = null

	erro: null | Error = null

	criaSkuAutomaticamente = false

	saveUseCase = new SaveProdutoUseCase()
	findUseCase = new FindProdutoUseCase()

	tipos: {
		text: string
		value: 'Padrão' | 'Com Variantes' | 'Composto'
		descricao: string
	}[] = [
		{
			text: 'Padrão',
			value: 'Padrão',
			descricao: 'Produto único e sem variantes',
		},
		{
			text: 'Com Variantes',
			value: 'Com Variantes',
			descricao: 'Possui variações como Cor e Tamanho',
		},
		{
			text: 'Composto',
			value: 'Composto',		
			descricao: 'Composto por mais produtos como "Kits" ou "Combos"',
		},
	]

	@Watch('id', { immediate: true })
	onChangeId() {
		this.carregar()
	}

	async carregar() {
		try {
			this.carregando = true
			this.criaSkuAutomaticamente = false
			this.produto = criarProduto()
			if (!this.novo)
				this.produto = await this.findUseCase.findProdutoById(this.id as string)
			this.atualizarVersaoAnterior()
			this.versaoInicial = this.produto
			if(this.produto.urlImagens) {
				const padrao = this.produto.urlImagens.filter(imagem => imagem.padrao)[0]
				this.img = padrao
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.$nextTick(() => {
				this.carregando = false
			})
		}
	}

	get podeConcederDesconto() {
		return UserLoginStore.permiteRegraDeNegocio('pode-conceder-desconto')
	}

	async salvar() {
		if (!this.produto) return

		if(this.produto.produtoIntegrado && !this.podeEditarProdutoIntegrado) 
			return AlertModule.setError("Esse usuário não possui a permissão para editar produtos integrados")
		if (this.produto.tipo === 'Composto') {
			this.produto.descontoVarejo = 0
		}

		try {
			this.salvando = true
			const produto = {
				...this.produto,
				isValePresente: this.produto.tipo === 'Padrão' 
					? this.produto.isValePresente 
					: false,
			} as Produto
			this.atualizarVersaoAnterior()
			if (produto.tipo === 'Com Variantes') {
				const regrasFiscaisDasVariantes = produto.regrasFiscais.map(regraFiscal => ({
					...regraFiscal,
					regra: {
						id: regraFiscal.regra.id,
						descricao: regraFiscal.regra.descricao,
						origem: regraFiscal.regra.origem,
						regimeFiscal: regraFiscal.regra.regimeFiscal,
					},
				})) as RegraFiscalDeProduto[]

				produto.variantes = produto.variantes.map(variante => ({
					...variante,
					nome: variante.nome || produto.nome,
					ncm: produto.ncm,
					cest: produto.cest,
					preco: variante.preco || produto.preco,
					pesoLiquido: produto.pesoLiquido !== this.versaoAnterior?.pesoLiquido? produto.pesoLiquido : variante.pesoLiquido,
					pesoBruto: produto.pesoBruto !== this.versaoAnterior?.pesoBruto ? produto.pesoBruto : variante.pesoBruto,
					descricao: variante.descricao || produto.descricao,
					marca: produto.marca,
					tags: variante.tags,
					temGarantia: produto.temGarantia,
					regrasFiscais: [ ...regrasFiscaisDasVariantes ],
					categoria: produto.categoria,
					unidadeDeMedida: produto.unidadeDeMedida,
					produtoDesativado: variante.produtoDesativado,
					desconto: variante.descontoVarejo || produto.descontoVarejo,
					fatorDeConversao: variante.fatorDeConversao,
					unidadeDeConversao: variante.unidadeDeConversao,
				}))
			}
			const produtoAtualizado: Produto = this.novo
				? await this.saveUseCase.create(produto as Produto)
				: await this.saveUseCase.update(produto as Produto)
			this.novo
				? ProdutoModule.addProduto(produtoAtualizado)
				: ProdutoModule.atualizaProduto(produtoAtualizado)

			AlertModule.setSuccess('Produto salvo com sucesso')
			if (this.novo) {
				this.$router.push({
					name: 'Produto', 
					params: {
						id: produtoAtualizado.id as string,
					},
				})
			}
			this.produto = null
			this.produto = produtoAtualizado
			AlertModule.setSuccess('Produto salvo com sucesso')
			this.atualizarVersaoAnterior()
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.carregar()
		}
	}

	get labelDoCampoDePreco() {
		switch (this.produto?.tipo) {
			case 'Composto':
				return 'Preço Total'
			case 'Com Variantes':
				return 'Preço Base'
			default:
				return 'Preço'
		}
	}

	get labelDoCampoDePesoLiquido() {
		switch (this.produto?.tipo) {
			case 'Com Variantes':
				return 'Peso Liquido Base'
			default:
				return 'Peso Liquido'
		}
	}

	get labelDoCampoDePesoBruto() {
		switch (this.produto?.tipo) {
			case 'Com Variantes':
				return 'Peso Bruto Base'
			default:
				return 'Peso Bruto'
		}
	}

	get ComponenteDoTipoDeProduto() {
		switch (this.produto?.tipo) {
			case 'Padrão':
				return 'CamposDeProdutoPadrao'
			case 'Com Variantes':
				return 'CamposDeProdutoComVariantes'
			case 'Composto':
				return 'CamposDeProdutoComposto'
			default:
				return 'div'
		}
	}

	get podeEditar() {
		return this.edicaoGenerica.podeEditar
	}


	get podeEditarProdutoIntegrado() {
		return UserLoginStore.permiteRegraDeNegocio('pode-editar-produto-integrado')
	}
	@Watch('produto.tipo')
	onChangeTipoDeProduto(tipo: TipoDeProduto) {
		if (!this.produto) return

		switch (tipo) {
			case 'Padrão':
				this.produto = converterProdutoParaProdutoPadrao(this.produto)
				break
			case 'Com Variantes':
				this.produto = converterProdutoParaProdutoComVariantes(
					this.produto,
				)
				break
			case 'Composto':
				this.produto = converterProdutoParaProdutoComposto(this.produto)
				break
			default:
				throw new Error('Conversão inesperada')
		}
	}

	async beforeRouteUpdate(to,from,next) {
		next(!await this.edicaoGenerica.validarTransicao())
	}

	async beforeRouteLeave(to, from, next) {
		next(!await this.edicaoGenerica.validarTransicao())
	}

	atualizarVersaoAnterior() {
		nextTick().then(() => {
			this.versaoAnterior = JSON.parse(JSON.stringify(this.produto))
		})
	}
}
