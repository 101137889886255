var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{attrs:{"eager":""}},[_c('v-expansion-panel-header',{staticClass:"header-variante",attrs:{"data-cy":"header-variante"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}}},[_c('div',{staticClass:"flex-grow-0",staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-avatar',{attrs:{"color":"primary"}},[_c('v-img',{attrs:{"src":!_vm.img ?null :_vm.img.imagemUrl},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%","width":"100%"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-tag")])],1)]},proxy:true}])})],1),_c('v-icon',{staticClass:"icone-editar-foto",attrs:{"small":""}},[_vm._v("mdi-camera")])],1),_c('div',{staticClass:"flex-grow-1 d-flex"},[_c('v-list-item-title',{staticClass:"flex-grow-1 pl-3"},[_c('v-text-field',{attrs:{"value":!_vm.variante.nome || _vm.variante.nome === _vm.produtoPai.nome ? ((_vm.produtoPai.nome) + " " + (_vm.displayVariantes(_vm.variante))) : _vm.variante.nome,"append-icon":_vm.variante.nome !== null ? 'mdi-refresh' : undefined},on:{"input":function (valor) { return (_vm.variante.nome = !valor
								? null
								: valor); },"click":function($event){$event.stopPropagation();},"click:append":function () { return (_vm.variante.nome = ''); }}})],1),(!_vm.skuAutomatico)?_c('v-text-field',{staticClass:"pl-3 flex-grow",attrs:{"data-cy":"sku","label":"SKU","value":_vm.variante.sku === null || _vm.variante.sku === '' ? _vm.produtoPai.sku : _vm.variante.sku,"append-icon":_vm.variante.sku ? 'mdi-refresh' : undefined},on:{"click":function($event){$event.stopPropagation();},"input":function (valor) { return _vm.variante.sku = valor; },"click:append":function () { return (_vm.variante.sku = ''); }}}):_vm._e()],1),_c('v-btn',{staticClass:"flex-grow-0",attrs:{"icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.$emit('excluir', _vm.variante); }).apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":""}},[_c('SeletorDeUnidadeDeMedida',{attrs:{"label":"Unidade de Conversão"},model:{value:(_vm.variante.unidadeDeConversao),callback:function ($$v) {_vm.$set(_vm.variante, "unidadeDeConversao", $$v)},expression:"variante.unidadeDeConversao"}})],1),_c('v-col',{attrs:{"cols":"6","sm":""}},[_c('v-text-field',{attrs:{"label":"Fator de Conversão","type":"number","autocomplete":"off","step":"1"},model:{value:(_vm.variante.fatorDeConversao),callback:function ($$v) {_vm.$set(_vm.variante, "fatorDeConversao", _vm._n($$v))},expression:"variante.fatorDeConversao"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"3"}},[_c('v-text-field',{attrs:{"type":"number","step":"0.01","label":"Preço","prefix":"R$","value":_vm.variante.preco === null ? _vm.produtoPai.preco : _vm.variante.preco,"append-icon":_vm.variante.preco !== null ? 'mdi-refresh' : undefined},on:{"input":function (preco) { return (_vm.variante.preco = preco === null || preco === ''
								? preco
								: Number(preco)); },"click:append":function () { return (_vm.variante.preco = null); }}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"type":"number","step":"0.01","label":"Peso Bruto","prefix":"KG","value":_vm.variante.pesoBruto === null ? _vm.produtoPai.pesoBruto : _vm.variante.pesoBruto,"append-icon":_vm.variante.pesoBruto !== null ? 'mdi-refresh' : undefined},on:{"input":function (peso) { return (_vm.variante.pesoBruto = peso === null || peso === ''
								? peso
								: Number(peso)); },"click:append":function () { return (_vm.variante.pesoBruto = null); }}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"type":"number","step":"0.01","label":"Peso Liquido","prefix":"KG","value":_vm.variante.pesoLiquido === null ? _vm.produtoPai.pesoLiquido : _vm.variante.pesoLiquido,"append-icon":_vm.variante.pesoLiquido !== null ? 'mdi-refresh' : undefined},on:{"input":function (peso) { return (_vm.variante.pesoLiquido = peso === null || peso === ''
								? peso
								: Number(peso)); },"click:append":function () { return (_vm.variante.pesoLiquido = null); }}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Desconto","prefix":"%","value":_vm.variante.descontoVarejo},on:{"input":function (desconto) { return (_vm.variante.descontoVarejo = desconto === null || desconto === 0
								? desconto
								: Number(desconto)); }}})],1),_c('v-col',{attrs:{"sm":"5"}},[_c('v-combobox',{attrs:{"data-cy":"ean-variante","label":"EANs","multiple":""},model:{value:(_vm.variante.eans),callback:function ($$v) {_vm.$set(_vm.variante, "eans", $$v)},expression:"variante.eans"}})],1),_c('v-col',{staticClass:"mt-1 ml-2"},[_c('v-checkbox',{attrs:{"label":"Desativar variante"},on:{"click":_vm.changeVariante},model:{value:(_vm.variante.produtoDesativado),callback:function ($$v) {_vm.$set(_vm.variante, "produtoDesativado", $$v)},expression:"variante.produtoDesativado"}})],1),_c('v-col',[_c('SeletorDeTag',{attrs:{"data-cy":"tag","label":"Tags","multiple":"","podeCriar":"","podeEditar":"","return-object":"","small-chips":"","auto-select-first":"","autocomplete":"off"},model:{value:(_vm.variante.tags),callback:function ($$v) {_vm.$set(_vm.variante, "tags", $$v)},expression:"variante.tags"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Descrição","value":_vm.variante.descricao || _vm.produtoPai.descricao,"append-icon":_vm.variante.descricao !== null ? 'mdi-refresh' : undefined},on:{"input":function (valor) { return (_vm.variante.descricao = valor || null); },"click:append":function () { return (_vm.variante.descricao = null); }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }