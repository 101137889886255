














import { Vue, Component, PropSync } from 'vue-property-decorator'
import EdicaoDeRegrasFiscaisDoProduto from '@/components/produto/EdicaoDeRegrasFiscaisDoProduto.vue'
import type { FormProduto } from '@/models'

@Component({
	components: {
		EdicaoDeRegrasFiscaisDoProduto,
	},
})
export default class CamposAvancadosDoProduto extends Vue {
	@PropSync('value') produto!: FormProduto
}
