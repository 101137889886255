import { render, staticRenderFns } from "./PanelDeEdicaoDaVariante.vue?vue&type=template&id=32557ff1&scoped=true&"
import script from "./PanelDeEdicaoDaVariante.vue?vue&type=script&lang=ts&"
export * from "./PanelDeEdicaoDaVariante.vue?vue&type=script&lang=ts&"
import style0 from "./PanelDeEdicaoDaVariante.vue?vue&type=style&index=0&id=32557ff1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32557ff1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAvatar,VBtn,VCheckbox,VCol,VCombobox,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon,VImg,VListItemTitle,VRow,VTextField,VTextarea})
