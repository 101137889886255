











import { Vue, Component } from 'vue-property-decorator'
import { listaDeUnidadesDeMedida } from '@/shareds/produto-shareds'

@Component
export default class SeletorDeUnidadeDeMedida extends Vue {
	searchInput = null

	itens = listaDeUnidadesDeMedida
}

